<template>
  <main class="tourism page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <section class="filter desktop-filter">
      <div class="filter__item filter__item-departure">
        <label class="label filter__label">{{ $t("From") }}</label>
        <input class="input filter__input" type="text" v-model="filters.from" />
      </div>

      <div class="filter__item filter__item-arrival">
        <label class="label filter__label" for="to">{{ $t("To") }}</label>
        <vue-select
          :options="arrival.options"
          class="input cus-select border-option-dropdown"
          v-model="arrival.selected"
        ></vue-select>
      </div>

      <div class="filter__item filter__item-date">
        <label class="label filter__label" for>{{ $t("Dates") }}</label>
        <date-range-picker v-model="filters.dateRange" class="range-datepicker">
        </date-range-picker>
      </div>

      <div class="filter__item filter__item-people">
        <label class="label filter__label" for>{{ $t("Passengers") }}</label>
        <vue-select
          :options="passengers.options"
          class="input cus-select border-option-dropdown"
          v-model="passengers.selected"
          :placeholder="$t('Select')"
        ></vue-select>
      </div>

      <div class="filter__item filter__item-budget">
        <label class="label filter__label" for>{{ $t("Budget") }}</label>
        <vue-select
          :options="budget.options"
          class="input cus-select border-option-dropdown"
          v-model="budget.selected"
          :placeholder="$t('Select')"
        ></vue-select>
      </div>

      <div class="filter__item filter__item-hospitality">
        <label class="label filter__label" for>{{ $t("Degree of hospitality") }}</label>
        <vue-select
          :options="stars.options"
          class="input cus-select border-option-dropdown"
          v-model="stars.selected"
          :placeholder="$t('Select')"
        ></vue-select>  
      </div>

      <div class="filter__item filter__item-deal">
        <label class="label filter__label" for>{{ $t("Type of Deal") }}</label>
        <vue-select
          :options="deal.options"
          class="input cus-select border-option-dropdown"
          v-model="deal.selected"
          :placeholder="$t('Select')"
        ></vue-select>  
      </div>

      <button class="button filter__button" @click="clickFilterBtn()"></button>
    </section>
    <section class="mobile-filter-modal" v-show="isFilterModalVisible">
      <p class="fs20 color-white text-center mb-5">{{ $t("Search your desire") }}</p>
      <div class="filter__item">
        <div class="d-flex align-items-center mb-4">
          <img src="/assets/img/plane.svg" alt="">
          <label class="label color-white mx-5">{{ $t("From") }}</label>
        </div>
        <input class="input filter__input" type="text" v-model="filters.from" />
      </div>

      <div class="filter__item">
        <div class="d-flex align-items-center mb-4">
          <img src="/assets/img/plane-arrival.svg" alt="">
          <label class="label color-white mx-5">{{ $t("To") }}</label>
        </div>
        <vue-select
          :options="arrival.options"
          class="input cus-select border-option-dropdown"
          v-model="arrival.selected"
        ></vue-select>
      </div>

      <div class="filter__item">
        <div class="d-flex align-items-center mb-4">
          <img src="/assets/img/calendar.svg" alt="">
          <label class="label color-white mx-5">{{ $t("Dates") }}</label>
        </div>
        <date-range-picker v-model="filters.dateRange" class="range-datepicker">
        </date-range-picker>
      </div>

      <!-- <div class="filter__item filter__item-date">
        <label class="label filter__label" for>{{ $t("Dates") }}</label>
        <date-range-picker v-model="filters.dateRange" class="range-datepicker">
        </date-range-picker>
      </div> -->

      <div class="filter__item">
        <div class="d-flex align-items-center mb-4">
          <img src="/assets/img/people.svg" alt="">
          <label class="label color-white mx-5">{{ $t("Passengers") }}</label>
        </div>
        <vue-select
          :options="passengers.options"
          class="input cus-select border-option-dropdown"
          v-model="passengers.selected"
        ></vue-select>
      </div>

      <div class="filter__item">
        <div class="d-flex align-items-center mb-4">
          <img src="/assets/img/money-wave.svg" alt="">
          <label class="label color-white mx-5">{{ $t("Budget") }}</label>
        </div>
        <vue-select
          :options="budget.options"
          class="input cus-select border-option-dropdown"
          v-model="budget.selected"
          :placeholder="$t('Select')"
        ></vue-select>
      </div>

      <div class="filter__item">
        <div class="d-flex align-items-center mb-4">
          <img src="/assets/img/stars.svg" alt="">
          <label class="label color-white mx-5">{{ $t("Degree of hospitality") }}</label>
        </div>
        <vue-select
          :options="stars.options"
          class="input cus-select border-option-dropdown"
          v-model="stars.selected"
          :placeholder="$t('Select')"
        ></vue-select>
      </div>

      <div class="filter__item">
        <div class="d-flex align-items-center mb-4">
          <img src="/assets/img/icons/umbrella-beach-light.svg" alt="">
          <label class="label color-white mx-5">{{ $t("Type of Deal") }}</label>
        </div>
        <vue-select
          :options="deal.options"
          class="input cus-select border-option-dropdown"
          v-model="deal.selected"
          :placeholder="$t('Select')"
        ></vue-select>
      </div>

      <div class="d-flex justify-content-center align-items-center filter-action-block mt-5">
        <button class="primary-btn" @click="clickFilterBtn()">
          {{ $t('Search') }}
          <img 
            src="/assets/img/icons/search-light-white.svg" 
            alt="" 
            class="seach-btn-img"
          >
        </button>
        <button 
          class="primary-btn bg-red6"  
          @click="closeFilterModal()"
        >
          {{ $t("Cancel") }}
        </button>
      </div>
    </section>
    <banner-section location="tourism_home-top"/>
    <TourismDirection v-if="!isStep2 && !isShowTourismDealCarousel" ref="tourismDirection"></TourismDirection>

    <div class="justify-content-center mt-7 mobile-filter-buttons mb-5">
      <button 
        class="primary-btn d-flex align-items-center mx-2"
        @click="openFilterModal"
      >
        {{ $t('Search') }}
        <img src="/assets/img/icons/search-light-white.svg" alt="" class="seach-btn-img mx-5">
      </button>
      <button class="primary-btn mx-2" @click="openDirectionModal">
        {{ $t('Top categories quick select') }}
      </button>
    </div>
    <TourismLogos v-if="!isStep2 && !isShowTourismDealCarousel"></TourismLogos>


    <section v-if="!isStep2">
      <div v-if="isViewAll" class="tourism-container-content tourism-slick_container">
        <div class="d-flex justify-content-between tourism__title">
          <h2 class="title title__h2">
            {{ $t('Organized Tours') }}
          </h2>
          <div class="d-flex justify-content-between">
            <SortTourism @sortDeals="sortDeals" :isTourismDeal="false"></SortTourism>
            <!-- <FilterTourism></FilterTourism> -->
          </div>
        </div>
        <div class="d-flex flex-wrap">
          <CarouselUnit 
            :deal="deal" 
            v-for="(deal, index) in oneTypeCheapestDeals"
            :key="index"
            class="tourism-unit"
            @getDealsFromIdType="clickCheapestDeal"
          >
          </CarouselUnit>
        </div>
      </div>
      <div
        v-else 
        class="tourism-slick_container"
        v-for="(deals, key) in groupedCheapestDeals"
        :key="key"
      >
        <section
          class="tourism__cheapest_nofshon"
          v-if="deals.length"
        >
          <h2 class="title title__h2 tourism__title desktop-tourism__title">
            <p v-if="key == 'ORGANIZED_TOUR'">{{ $t("Organized tours to selected destinations") }}</p> 
            <p v-else>{{ $t(key) }}</p>
            <p class="pointer fs16" @click="showViewAll(key)">{{ $t(getDealViewAllTextFromType(key)) }}</p>
          </h2>
          <p class="mobile-tourism__title my-2">{{ $t(key) }}</p> 
          <VueSlickCarousel
            v-bind:slidesToShow="itemPerSlide"
            class="cus-slick-carousel tourism-carousel"
            :class="deals.length<4 ? sliderDirection : ''"
            :rtl="sliderDirection=='rtl' ? true : false"
          >
            <CarouselUnit 
              :deal="deal" 
              v-for="(deal, index) in deals"
              :key="index"
              @getDealsFromIdType="clickCheapestDeal"
            >
            </CarouselUnit>
          </VueSlickCarousel>
          <p class="pointer fs20 mobile-tourism__title my-5" @click="showViewAll(key)">{{ $t(getDealViewAllTextFromType(key)) }}</p>
        </section>
      </div>
      <loading :active.sync="cheapestDealsAllLoading" :can-cancel="false" :is-full-page="false"></loading>
    </section>
    <section v-if="isStep2" class="tourism-slick_container">
      <div v-if="!isShowTourismDealCarousel" class="height-side-filter">
        <div class="d-flex justify-content-between mt-15 mb-5 align-items-baseline">
          <h2 class="title title__h2 tourism__title px-5 fs23">
            {{ tourismDes }}
          </h2>
          <div class="d-flex justify-content-between">
            <SortTourism @sortDeals="sortDeals" :isTourismDeal="true" v-if="gsv(sortedTourismDeals, ['items'],  []).length"></SortTourism>
            <FilterTourism></FilterTourism>
          </div>
        </div>
        <div>
          <div class="d-flex flex-wrap" v-if="gsv(sortedTourismDeals, ['items'],  []).length">
            <TourismUnit class="tourism-unit"
              v-for="(deal, index) in filteredTourismData"
              :key="index"
              :deal="deal" 
            >
            </TourismUnit>
          </div>
          <button 
            class="primary-btn btn-limit-width load-more_btn" 
            @click="loadMore()" 
            v-if="gsv(sortedTourismDeals, ['items'],  []).length < sortedTourismDeals.items_total"
          >
            {{ $t("Show more") }}
          </button>
          <h2 v-else 
            class="text-center mt-10 mb-10"
          >
            <span v-show="!loading">{{ $t('No data') }}</span>
          </h2>
        </div>
      </div>
      <div v-else>
        <section
          class="tourism__cheapest_nofshon"
          v-if="!loading && nofshonDeals.items.length"
        >
          <h2 class="title title__h2 tourism__title">
            <p>{{ $t('NOFSHON') }}</p> 
            <p class="pointer fs16" @click="showViewTourismList('NOFSHON')">{{ $t(getDealViewAllTextFromType('NOFSHON')) }}</p>
          </h2>
          <p class="mobile-tourism__title my-2">
            {{ $t('NOFSHON') }}
          </p>
          <VueSlickCarousel
            v-bind:slidesToShow="itemPerSlide"
            class="cus-slick-carousel tourism-carousel"
            :class="nofshonDeals.items.length<4 ? sliderDirection : ''"
            :rtl="sliderDirection=='rtl' ? true : false"
          >
            <TourismUnit 
              :deal="deal" 
              v-for="(deal, index) in nofshonDeals.items"
              :key="index"
              class="px-5"
            >
            </TourismUnit>
          </VueSlickCarousel>
          <p class="mobile-tourism__title my-5" @click="showViewTourismList('NOFSHON')">
            {{ $t(getDealViewAllTextFromType('NOFSHON')) }}
          </p>
        </section>
        <section
          class="tourism__cheapest_nofshon"
          v-if="!loading && organizedDeals.items.length"
        >
          <h2 class="title title__h2 tourism__title">
            <p>{{ $t('Organized Tour') }}</p> 
            <p class="pointer fs16" @click="showViewTourismList('ORGANIZED_TOUR')">{{ $t(getDealViewAllTextFromType('ORGANIZED_TOUR')) }}</p>
          </h2>
          <p class="mobile-tourism__title my-2">
            {{ $t('Organized Tour') }}
          </p>
          <VueSlickCarousel
            v-bind:slidesToShow="itemPerSlide"
            class="cus-slick-carousel tourism-carousel"
            :class="organizedDeals.items.length<4 ? sliderDirection : ''"
            :rtl="sliderDirection=='rtl' ? true : false"
          >
            <TourismUnit 
              :deal="deal" 
              v-for="(deal, index) in organizedDeals.items"
              :key="index"
              class="px-5"
            >
            </TourismUnit>
          </VueSlickCarousel>
          <p class="mobile-tourism__title fs16 my-5" @click="showViewTourismList('ORGANIZED_TOUR')">{{ $t(getDealViewAllTextFromType('ORGANIZED_TOUR')) }}</p>
        </section>
        <section v-if="!loading && !nofshonDeals.length && !organizedDeals.items.length" class="text-center mt-15 mb-15">
          <h1>No data</h1>
        </section>
      </div>
    </section>
    <div class="tourism-slick_container">
      <section class="tourism__special">
        <h2 class="title title__h2 tourism__title">{{ $t("Promotions") }}</h2>
        <VueSlickCarousel
          v-bind:slidesToShow="itemPerSlide"
          class="cus-slick-carousel tourism-carousel"
          :rtl="sliderDirection=='rtl' ? true : false"
        >
          <div class="tourism-carousel_unit"
            v-for="num in 8"
            :key="num"
          >
            <div class="category-slider tourism__unit">
              <div class="category-slider__img-wrapper tourism__image-wrapper">
                <img class="category-slider__img tourism__image" src="/assets/img/london2.png" alt />
              </div>

              <div class="tourism__info">
                <a class="link tourism__info-link" href="tourism-category.html">
                  <b class="tourism__info-place">{{ $t("London") }}</b>
                  <div class="tourism__info-offers">
                    <span class="tourism__offers-value">306</span>
                    <span class="tourism__span">{{ $t("Offers") }}</span>
                  </div>
                  <div class="tourism__info-price">
                    <span class="tourism__span">{{ $t("Starting from") }}</span>
                    <span class="tourism__price-value">$159</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
      </section>
    </div>

    <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
  </main>
</template>

<script>
import { mapState, mapActions } from "vuex";

import DateRangePicker from "@/components/dateRangePicker2/components/DateRangePicker"
import "@/components/dateRangePicker2/assets/daterangepicker.scss";
import  CategoryShare from "@/components/CategoryShare";
import  RateStar from "@/components/RateStar";
import  TourismDirection from "@/components/tourism/TourismDirection";
import CarouselUnit from "@/components/tourism/CarouselUnit";
import TourismUnit from "@/components/tourism/TourismUnit"
import SortTourism from "@/components/tourism/SortTourism"
import FilterTourism from "@/components/tourism/FilterTourism";
import TourismLogos from "@/components/tourism/TourismLogos";
import BannerSection from "@/components/BannerSection";


export default {
  name: "Tourism",
  components: {
    BannerSection,
    DateRangePicker,
    CategoryShare,
    RateStar,
    TourismDirection,
    CarouselUnit,
    TourismUnit,
    SortTourism,
    FilterTourism,
    TourismLogos
  },
  data() {
    // with all current filters values
    let startDate = new Date();
    let endDate = new Date();
    endDate.setDate(endDate.getDate() + 90);
    let night = ['1', '89'];
    return {
      filters: {
        from: "TLV",
        dateRange: { 
          startDate: startDate, 
          endDate: endDate, 
          night: night 
        },
        exact_dates: false
      },
      arrival: {
        options: [],
        selected: '',
      },
      passengers: {
        options: [
          {
            code: '',
            label: this.$t('All')
          },
          {
            code: 'AA',
            label: 2 + " " + this.$t('Adults')
          },
          {
            code: 'AAC',
            label: 2 + " " + this.$t('Adults') + " + " + 2 + " " + this.$t('Boy')
          },
          {
            code: 'AACC',
            label: 2 + " " + this.$t('Adults') + " + " + 2 + " " + this.$t('Children')
          },
        ],
        selected: {
          code: '',
          label: this.$t('All')
        }
      },
      budget: {
        options: [
          {
            code: '',
            label: this.$t('All')
          },
          {
            code: '200',
            label: '<' + 200
          },
          {
            code: '500',
            label: '<' + 500
          },
          {
            code: '750',
            label: '<' + 750
          },
          {
            code: '10000',
            label: '<' + 10000
          },
        ],
        selected: {
          code: '',
          label: this.$t('All')
        }
      },
      stars: {
        options: [
          {
            code: '',
            label: this.$t('All')
          },
          {
            code: '1',
            label: 1
          },
          {
            code: '2',
            label: 2
          },
          {
            code: '3',
            label: 3
          },
          {
            code: '4',
            label: 4
          },
          {
            code: '5',
            label: 5
          },
        ],
        selected:  {
          code: '',
          label: this.$t('All')
        }
      },
      deal: {
        options: [
          {
            code: '',
            label: this.$t("All")
          },
          {
            code: 'NOFSHON',
            label: this.$t("NOFSHON")
          },
          {
            code: 'ORGANIZED_TOUR',
            label: this.$t("Organized Tour")
          },
        ],
        selected: {
          code: '',
          label: this.$t("All")
        }
      },
      isStep2: false,
      isViewAll: false,
      // isShowSort: false,
      oneTypeCheapestDeals: [],
      sortedTourismDeals: {},
      tourismDes: "",
      nofshonDeals: {},
      organizedDeals: {},
      // isShowTourismDealCarousel: false,
      isClickCheapestDeal: false,
      isFilterModalVisible: false
    };
  },
  mounted() {
    moduleFavorites.init();
    moduleFilter.init();
    moduleModalBooking.init();
    moduleModalDiscount.init();
    moduleTourism.init();
    moduleTourismProd.init();
    moduleTrip.init();

  },
  computed: {
    ...mapState({
      loading: (state) => state.deal.loading,
      cheapestDealsAllLoading: (state) => state.deal.cheapestDealsAllLoading,
      destinations: (state) => state.destination.destinationData_receipts,
      deal_data: (state) => state.deal.dealData_receipts,
      cheapestDealsData: (state) => state.deal.cheapestDealsAllData,
      specificDeals: (state) => state.deal.specificDeals_receipts,
      filterData: (state) => state.deal.filterData,
      isShowTourismDealCarousel: (state) => state.deal.isShowTourismDealCarousel,
      sideFilterData: (state) => state.deal.sideFilterData
    }),
    groupedCheapestDeals() {
      if (Array.isArray(this.cheapestDealsData)) {
        return this.groupByType(this.cheapestDealsData);
      }
    }, 
    routerID() {
      return this.$route.query.id;
    },
    routerType() {
      return this.$route.query.type;
    },
    allType() {
      if (this.$route.path == '/tourism/NOFSHON') {
        return 'NOFSHON';
      } else if (this.$route.path == '/tourism/ORGANIZED_TOUR') {
        return 'ORGANIZED_TOUR';
      } else {
        return "";
      }
    },
    filteredTourismData() {
      let params = {
        nights: [],
        stars: [],
        roomType: [],
      }

      let result = [];

      this.sideFilterData.nights.forEach(el => {
        if (el.value) params.nights.push(el.label);
      });
      this.sideFilterData.stars.forEach(el => {
        if (el.value) params.stars.push(el.label);
      });
      this.sideFilterData.roomType.forEach(el => {
        if (el.value) params.roomType.push(el.label);
      });

      result = this.sortedTourismDeals.items.filter(el => {
        let condition1 = true;
        let condition2 = true;
        let condition3 = true;
        let condition4 = false;
        let condition5 = false;
        if (params.nights.length) {
          condition1 = params.nights.includes(el.dealLength);
        }
        if (params.stars.length) {
          condition2 = params.stars.includes(el.hotelRating);
        }
        if (params.roomType.length) {
          condition3 = params.roomType.includes(el.roomType);
        }

        el.flightsPairs.forEach(ele => {
          const toDepTime = ele.to.depTime.replace(':', '')*1;
          if (this.sideFilterData.time.takeOffTLV[0] <= toDepTime && toDepTime <= this.sideFilterData.time.takeOffTLV[1] ) {
            condition4 = true;
          }
          const fromDepTime = ele.from.depTime.replace(':', '')*1;
          if (this.sideFilterData.time.takeOffDes[0] <= fromDepTime && fromDepTime <= this.sideFilterData.time.takeOffDes[1] ) {
            condition5 = true;
          }
        });

        return condition1 && 
          condition2 && 
          condition3 && 
          condition4 && 
          condition5 && 
          el.dealPricePerPerson >= this.sideFilterData.priceSliderValue[0] && 
          el.dealPricePerPerson <= this.sideFilterData.priceSliderValue[1];
      })

      return result;
    }
  },
  async created() {
    if (this.destinations.length == 0) {
      await this.getDestination();
    }

    if (this.destinations.length != 0) {
      for (const key in this.destinations) {
        if (this.destinations[key].active) {
          this.arrival.options.push({
            label: this.destinations[key].name,
            code: this.destinations[key].id,
          });
        }
      }
      this.arrival.options.unshift({
        label: this.$t("All destinations"),
        code: null,
      });
      this.arrival.selected = this.arrival.options[0];
    }

    if (this.routerID) {
      this.getDealsFromIdType({
        id: this.routerID,
        type: this.routerType
      })
    } else {
      if (this.cheapestDealsData.length == 0) {
        // await this.filter_deal(0);
        await this.getCheapestDealsAll();
      }
      
      // let tempType = [];
      // this.cheapestDealsData.forEach(el => {
      //   let type = el.type;
      //   if (!tempType.includes(type)) {
      //     tempType.push(type);
      //     this.deal.options.push({
      //       code: type,
      //       label: this.$t(type)
      //     })
      //   }
      // });
      // this.deal.selected = this.deal.options[0];
    }

    if (this.allType == "NOFSHON") {
      this.showViewAll('NOFSHON');
    } else if (this.allType == "ORGANIZED_TOUR") {
      this.showViewAll('ORGANIZED_TOUR');
    }
  },
  methods: {
    ...mapActions("destination", {
      getDestination: "getDestination",
    }),
    ...mapActions("deal", {
      getDeal: "getDeal",
      getCheapestDealsAll: "getCheapestDealsAll",
      getDealsByIdType: "getDealsByIdType",
      saveFilterData: "saveFilterData",
      backupDealData: "backupDealData"
    }),
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },

    groupByType(arry) {
      let groupArry = {};
      arry.forEach(el => {
        let type = el.type;
        if (groupArry[type]) {
          groupArry[type].push(el);
        } else {
          groupArry[type] = [];
          groupArry[type].push(el);
        }
      })

      return groupArry;
    },
    /* This is for filter slider */
    createFilterData (data, page_num) {
      function compare(a, b) {
        if (a.label < b.label)
          return -1;
        if (a.label > b.label)
          return 1;
        return 0;
      }
      function timeCompare2(a, b) {
        if(a > b) { return 1; }
        if(a < b) { return -1; }    
        return 0;
      }

      let params = {
        des:"",
        nights: [],
        stars: [],
        roomType: [],
        priceSliderValue: [],
        defaultPriceSliderValue: [],
        time: {
          takeOffTLV: [],
          defaultTakeOffTLV: [],
          takeOffDes: [],
          defaultTakeOffDes: [],
          landingTLV: [],
          defaultLandingTLV: [],
          landingDes: [],
          defaultLandingDes: [],
        }
      }

      if (page_num != 0) {
        params = this.sideFilterData;
      }

      params.des = this.arrival.selected.label;
      let toDepTime = [];
      let fromDepTime = [];
      let toArrTime  = [];
      let fromArrTime  = [];
      data.forEach(el => {
        if (!params.nights.find(ele => ele.label == el.dealLength )) {
          let tempNight = {
            label: el.dealLength,
            value: false
          }
          params.nights.push(tempNight);
        }
        if (!params.stars.find(ele => ele.label == el.hotelRating )) {
          let tempStar = {
            label: el.hotelRating,
            value: false
          }
          params.stars.push(tempStar);
        }
        if (!params.roomType.find(ele => ele.label == el.roomType )) {
          let tempRoomType = {
            label: el.roomType,
            value: false
          }
          params.roomType.push(tempRoomType);
        }
        el.flightsPairs.forEach(ele => {
          toDepTime.push(ele.to.depTime.replace(':', '')*1);
          fromDepTime.push(ele.from.depTime.replace(':', '')*1);
          toArrTime.push(ele.to.arrTime.replace(':', '')*1);
          fromArrTime.push(ele.from.arrTime.replace(':', '')*1);
        });
      });

      params.priceSliderValue[0] = Math.min.apply(Math, data.map(function(o) { return o.dealPricePerPerson; }));
      params.priceSliderValue[1] = Math.max.apply(Math, data.map(function(o) { return o.dealPricePerPerson; }));
      params.defaultPriceSliderValue[0] = Math.min.apply(Math, data.map(function(o) { return o.dealPricePerPerson; }));
      params.defaultPriceSliderValue[1] = Math.max.apply(Math, data.map(function(o) { return o.dealPricePerPerson; }));
      
      toDepTime.sort(timeCompare2);
      params.time.takeOffTLV[0] = toDepTime[0];
      params.time.takeOffTLV[1] = toDepTime[toDepTime.length-1] || toDepTime[0];
      params.time.defaultTakeOffTLV[0] = toDepTime[0];
      params.time.defaultTakeOffTLV[1] = toDepTime[toDepTime.length-1] || toDepTime[0];
      
      fromDepTime.sort(timeCompare2);
      params.time.takeOffDes[0] = fromDepTime[0];
      params.time.takeOffDes[1] = fromDepTime[fromDepTime.length-1] || fromDepTime[0];
      params.time.defaultTakeOffDes[0] = fromDepTime[0];
      params.time.defaultTakeOffDes[1] = fromDepTime[fromDepTime.length-1] || fromDepTime[0];

      toArrTime.sort(timeCompare2);
      params.time.landingTLV[0] = toArrTime[0];
      params.time.landingTLV[1] = toArrTime[toArrTime.length-1] || toArrTime[0];
      params.time.defaultLandingTLV[0] = toArrTime[0];
      params.time.defaultLandingTLV[1] = toArrTime[toArrTime.length-1] || toArrTime[0];

      fromArrTime.sort(timeCompare2);
      params.time.landingDes[0] = fromArrTime[0];
      params.time.landingDes[1] = fromArrTime[fromArrTime.length-1] || fromArrTime[0];
      params.time.defaultLandingDes[0] = fromArrTime[0];
      params.time.defaultLandingDes[1] = fromArrTime[fromArrTime.length-1] || fromArrTime[0];
      
      params.nights = params.nights.sort(compare);
      params.stars = params.stars.sort(compare);
      params.roomType = params.roomType.sort(compare);

      this.$store.commit("deal/setSideFilterData", params);

    },

    async filter_deal(page_num) {
      let obj = this;
      if (page_num == 0) {
        obj.sortedTourismDeals = [];
      }
      obj.tourismDes = obj.arrival.selected.label;

      const start_date = obj.formatDate(obj.filters.dateRange.startDate);
      const end_date = obj.formatDate(obj.filters.dateRange.endDate);
      if (obj.filters.dateRange.night[0] === '') {
        obj.filters.exact_dates = true;
      }
      const defaultFilterData = {
        start_date: start_date,
        end_date: end_date,
        passengers: obj.passengers.selected.code,
        budget: obj.budget.selected.code,
        stars: obj.stars.selected.code,
        exact_dates: obj.filters.exact_dates,
        deal_length_min: obj.filters.dateRange.night[0],
        deal_length_max: obj.filters.dateRange.night[1]
      };
      let additionFilterData = {
        type: obj.deal.selected.code,
        from: obj.filters.from,  
        per_page: 40,
        page: page_num 
      };

      let params = {...defaultFilterData, ...additionFilterData}
      if (obj.arrival.selected.code !== null) {
        params.to = obj.arrival.selected.code;
        obj.isStep2 = true;
        const id = this.arrival.selected.code;
        const type = this.deal.selected.code || "all";
        if (id != this.routerID || type != this.routerType) {
          this.$router.push({ name: 'Tourism', query: { id, type }});
        }
        
        if (this.deal.selected.code == '') {
          params.type = 'NOFSHON';
          await obj.getDeal({ filters: params });
          this.nofshonDeals = obj.deal_data || [];

          params.type = 'ORGANIZED_TOUR';
          await obj.getDeal({ filters: params });
          this.organizedDeals = obj.deal_data || [];

          if (this.nofshonDeals?.items?.length && this.organizedDeals?.items?.length) {
            this.$store.commit("deal/setIsShowTourismDealCarousel", true);
            // this.isShowTourismDealCarousel = true;
          } else {
            this.$store.commit("deal/setIsShowTourismDealCarousel", false);
            // this.isShowTourismDealCarousel = false;
            if (this.nofshonDeals?.items?.length) {
              this.deal.selected = {
                code: 'NOFSHON',
                label: this.$t("NOFSHON")
              }
              this.sortedTourismDeals = this.nofshonDeals;
            } else if (this.organizedDeals?.items?.length) {
              this.deal.selected = {
                code: 'ORGANIZED_TOUR',
                label: this.$t("Organized Tour")
              }
              this.sortedTourismDeals = this.organizedDeals
            } else {
              this.sortedTourismDeals = [];
            }
            this.createFilterData(this.sortedTourismDeals.items, page_num);
          }
        } else {
          if (this.isClickCheapestDeal && Object.keys(this.filterData).length) {
            params = {...this.filterData,  ...additionFilterData};
            params.to = obj.arrival.selected.code;
          } 
          await obj.getDeal({ filters: params });
          // this.isShowTourismDealCarousel = false;
          this.$store.commit("deal/setIsShowTourismDealCarousel", false);
          this.sortedTourismDeals = this.deal_data || [];
          this.createFilterData(this.sortedTourismDeals.items, page_num);
        }
      } else {
        obj.isStep2 = false;
        obj.isViewAll = false;
        if (this.routerID) {
          this.$router.push({ name: 'Tourism'});
        }
        await obj.getCheapestDealsAll({ filters: params });
        if (!this.isClickCheapestDeal) {
          this.saveFilterData({ params: defaultFilterData });
        }
      }
      localStorage.setItem("passengers", obj.passengers.selected.code);
    },
    async getDealsFromIdType({id, type}) {
      if (type == 'all') {
        type = "";
      }
      this.arrival.selected = this.arrival.options.find(el => el.code == id) || {};
      this.deal.selected = this.deal.options.find(el => el.code == type) || {};

      this.filter_deal(0);
    },
    clickCheapestDeal({id, type}) {
      this.isClickCheapestDeal = true;
      this.getDealsFromIdType({id, type});
    },
    clickFilterBtn() {
      this.isClickCheapestDeal = false;
      this.filter_deal(0);
    },
    loadMore() {
      const page_num = this.sortedTourismDeals.page + 1;
      if (!this.deal_data.length) {
        this.backupDealData({data: this.sortedTourismDeals})
      }
      this.filter_deal(page_num);
    },
    sortDeals({type, isTourism}) {
      if (type == 'asc') {
        function compare(a, b) {
          if (a.dealPricePerPerson < b.dealPricePerPerson)
            return -1;
          if (a.dealPricePerPerson > b.dealPricePerPerson)
            return 1;
          return 0;
        }
        if (isTourism) {
          this.sortedTourismDeals.items = this.sortedTourismDeals.items.sort(compare);
        } else {
          this.oneTypeCheapestDeals = this.oneTypeCheapestDeals.sort(compare);
        }
      } else if (type == 'desc') {
        function compare(a, b) {
          if (a.dealPricePerPerson < b.dealPricePerPerson)
            return 1;
          if (a.dealPricePerPerson > b.dealPricePerPerson)
            return -1;
          return 0;
        }
        if (isTourism) {
          this.sortedTourismDeals.items = this.sortedTourismDeals.items.sort(compare);
        } else {
          this.oneTypeCheapestDeals = this.oneTypeCheapestDeals.sort(compare);
        }
      } else {
        function compare(a, b) {
          if (a.departureDate < b.departureDate)
            return -1;
          if (a.departureDate > b.departureDate)
            return 1;
          return 0;
        }
        if (isTourism) {
          this.sortedTourismDeals.items = this.sortedTourismDeals.items.sort(compare);
        } else {
          this.oneTypeCheapestDeals = this.oneTypeCheapestDeals.sort(compare);
        }
      }
    },
    /* show all cheapest deals */
    showViewAll(type) {
      this.isViewAll = true;
      console.log("--------------", type);
      this.oneTypeCheapestDeals = this.groupedCheapestDeals[type];
      this.deal.selected = this.deal.options.find((el) => {
        return el.code == type;
      });
      this.sortDeals({type: 'asc', isTourism: false});
    },
    showViewTourismList(type) {
      // this.isShowTourismDealCarousel = false;
      this.$store.commit("deal/setIsShowTourismDealCarousel", false);
      this.deal.selected = this.deal.options.find((el) => {
        return el.code == type;
      });
      if (type == 'NOFSHON') {
        this.sortedTourismDeals = this.nofshonDeals
      } else {
        this.sortedTourismDeals = this.organizedDeals
      }
      this.backupDealData({data: this.sortedTourismDeals})
    },
    openDirectionModal() {
      this.$refs.tourismDirection.openModal();
    },
    openFilterModal() {
      $("html").css("overflow", "hidden");
      this.isFilterModalVisible = true;
    },
    closeFilterModal() {
      $("html").css("overflow", "auto");
      this.isFilterModalVisible = false;
    }
  },
};
</script>

<style lang="scss">
/* slick carousel */
.tourism-carousel .slick-prev, .tourism-carousel .slick-next {
  width: 70px;
}
.tourism-carousel .slick-prev {
  left: -80px;
}
.tourism-carousel .slick-next {
  right: -80px;
}
.tourism-carousel_unit {
  padding: 8px;
}
.tourism-carousel.rtl * {
  direction: rtl !important;
}
.vue-daterange-picker[data-v-1badafbe] { position: relative; display: inline-block;}
 .range-datepicker {
    min-width: 140px!important;
    .reportrange-text {
      height: 38px;
    }
  }
@media screen and (max-width: 767px) {
  .filter__item {
    .range-datepicker .reportrange-text {
      height: 48px;
    }
    .vs__actions {
      padding: 7px 6px 0 3px;
      position: absolute;
      left: 20px;
    }
  }

  .mobile-filter-modal {
    .range-datepicker .reportrange-text {
      padding-top: 0 !important;
      height: 40px;
      padding: 0 15px !important;
    }
  }

  .ltr-type {
    .filter__item .vs__actions {
      left: unset;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      padding-top: 0;
    }
  }

  .tourism-carousel .slick-prev, .tourism-carousel .slick-next {
    width: 20px;
  }
  .tourism-carousel .slick-prev {
    left: -15px;
  }
  .tourism-carousel .slick-next {
    right: -15px;
  }
  .cus-slick-carousel .slick-prev::before, 
  .cus-slick-carousel .slick-next::before {
    background-size: contain;
    width: 12px;
    height: 21px;
  }
}
</style>

<style lang="scss" scoped>
.filter__item {
  flex-basis: calc(14% - 20px);
}

.filter__item-deal::before {
  content: "";
  width: 26px;
  height: 24px;
  background: url('/assets/img/icons/umbrella-beach-light.svg') no-repeat;
}
.filter__item-budget::before {
  width: 33px;
}
.tourism-slick_container {
  padding: 0 80px;
  @media screen and (max-width: 767px) {
    padding: 0 20px;
  }
}
.tourism-unit {
  padding: 0 10px;
  margin-bottom: 20px;
  width: 25%;
}
.load-more_btn {
  margin:  0 auto;
  margin-top: 40px;
}
.tourism__title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.tourism-container-content {
  margin-top: 55px;
}
.height-side-filter {
  min-height: 1350px;
}

.mobile-filter-buttons {
  display: none;
}

.mobile-tourism__title {
  display: none;
}

@media screen and (max-width: 1100px) {
  .tourism-unit {
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .filter__item {
    flex-basis: 100%;
    .cus-select {
      padding-right: 45px;
      padding-top: 12px;
      height: 48px;
    }
  }

  .tourism-unit {
    width: 100%;
  }

  .ltr-type {
    .filter__item {
      padding-left: 45px;
    }
    .mobile-filter-buttons {
      .primary-btn {
        .seach-btn-img {
          margin-left: 10px;
          margin-right: unset;
        }
      }
    }
    .mobile-filter-modal {
      .filter-action-block {
        .primary-btn {
          .seach-btn-img {
            margin-right: unset;
            margin-left: 15px;
          }
        }
      }
    }
  }
  .mobile-filter-buttons {
    display: flex;
    .primary-btn {
      background-color: #002036;
      width: auto;
      font-size: 12px;
      font-weight: initial;
      height: auto;
      padding: 5px;
      border-radius: 8px;
      .seach-btn-img {
        height: 15px;
        margin-right: 10px;
      }
    }
  }
  .desktop-filter {
    display: none;
  }
  .mobile-filter-modal {
    position: fixed;
    top: 60px;
    width: 100vw;
    left: 0;
    right: 0;
    background-color: #002036;
    height: calc(100vh - 60px);
    padding: 15px;
    z-index: 22;
    overflow: auto;
    .filter__item {
      padding: 0;
      height: unset;
      margin-top: 15px;
      &::after {
        display: none;
      }
      .cus-select {
        height: 40px;
        padding-top: 0;
      }
    }
    .filter-action-block {
      .primary-btn {
        padding: 0 20px;
        margin: 0 10px;
        border-radius: 5px;
        .seach-btn-img {
          margin-right: 15px;
        }
      }
    }
  }
  .desktop-tourism__title {
    display: none;
  }
  .mobile-tourism__title {
    display: block;
    text-align: center;
    font-weight: bold;
  }
  .tourism__cheapest_tour, .tourism__cheapest_nofshon {
    margin-top: 10px;
  }
  .tourism-slick_container {
    border-top: 1px solid #707070;
  }

}

</style>